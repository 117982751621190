import React from "react";
import logorodl from '../assets/images/rodl.png';
import logoch from '../assets/images/chLogo.png';
import logoahk from '../assets/images/ahk.png';
import logoGerman from '../assets/images/deGerman.png';
import logoAlpplas from '../assets/images/alpplas.png';
import logoema from '../assets/images/ema.webp';
import logoVideojet from '../assets/images/videojet.png';
import logoEmsan from '../assets/images/emsan.png';
import logoHazera from '../assets/images/hazera.png';
import logoNis from '../assets/images/nislogo.png';
import { useTranslation } from 'react-i18next';

const Works = () => {
  const { t, i18n } = useTranslation();
  const logos = [
    { src: logorodl, alt: "Rödl logo" },  
    { src: logoch, alt: "Swiss Consulate logo" },     
    { src: logoahk, alt: "German Chamber of Commerce logo" },  
    { src: logoGerman, alt: "German Chamber logo" },
    { src: logoAlpplas, alt: "Alpplas logo" },
    { src: logoema, alt: "EMA logo" },    
    { src: logoVideojet, alt: "Videojet logo" },
    { src: logoEmsan, alt: "Emsan logo" }, 
    { src: logoHazera, alt: "Hazera logo" }, 
    { src: logoNis, alt: "Nis logo" }    
  ];

  return (
    <section className="py-20 bg-gray-100" aria-label="Marcom">
      <h2 id="exampleswork" className="text-center text-2xl mb-12 font-bold text-red-800"aria-label="marka">{t('brand')}</h2>
      
 
      <div className="flex flex-wrap justify-center gap-6">
        {logos.map((logo, index) => (
          <div 
            key={index} 
            className="w-48 h-48 bg-white flex items-center justify-center border border-gray-300 shadow-lg overflow-hidden mx-2 transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-2xl" 
          >
            <img
              src={logo.src}
              alt={logo.alt}
              className={`w-full h-full object-contain transition-all duration-300 ease-in-out ${ index === 5 ? 'object-cover' : ''}`} 
              loading="lazy"
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Works;
