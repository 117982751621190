import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from 'react-i18next';
import { BuildingLibraryIcon, ChartPieIcon, ClipboardIcon } from "@heroicons/react/24/outline";

const DrcSystem = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 2000, 
      once: true, 
    });
  }, []);

  return (
    <div className="flex flex-wrap md:flex-nowrap bg-black min-h-screen pt-10 px-4 md:px-0">
      {/* Görsel ve Metin Bölümü */}
      <div
        className="w-full md:w-1/2 flex items-center justify-center bg-black"
        aria-label="Sosyal Medya"
      >
        <div
          className="relative w-80 h-80 sm:w-96 sm:h-96 flex items-center justify-center"
          data-aos="zoom-in"
          data-aos-duration="1500"
          aria-label="PR"
        >
          <div
            className="absolute w-full h-full rounded-full border-[50px] border-white"
            data-aos="rotate"
            data-aos-duration="2000"
            aria-hidden="true"
          ></div>
          <span
            className="text-white text-lg sm:text-xl md:text-2xl font-bold tracking-wide"
            data-aos="fade-up"
            data-aos-delay="800"
            aria-label="Web tasarım"
          >
            {t('drcsystem')}
          </span>
        </div>
      </div>

      {/* Bilgi Kartları Bölümü */}
      <div
        className="w-full md:w-1/2 bg-black text-[#d1d1d1] py-12 px-6 flex flex-col gap-10 justify-center items-center"
        aria-label="Information about DRC System"
      >
        {[
          { icon: <BuildingLibraryIcon className="w-7 h-7" />, title: t('goal'), desc: t('goals') },
          { icon: <ClipboardIcon className="w-7 h-7" />, title: t('planning'), desc: t('planningg') },
          { icon: <ChartPieIcon className="w-10 h-8" />, title: t('budget'), desc: t('budgett') }
        ].map((item, index) => (
          <div
            key={index}
            className="relative w-full rounded-md max-w-xl bg-white text-black p-8 border-2 border-gray-300 flex items-center justify-start gap-6 group"
            aria-label={`Information about ${item.title}`}
            data-aos="fade-up"
            data-aos-delay={index * 200}
            data-aos-duration="1500"
          >
            <div className="absolute left-0 top-0 h-full w-1 bg-[#791127] scale-y-0 group-hover:scale-y-100 transition-transform duration-300 origin-top"></div>
            <div aria-hidden="true">
              {item.icon}
            </div>
            <div>
              <h3 className="text-lg font-semibold" aria-label="web tasarım">{item.title}</h3>
              <p className="text-base">{item.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DrcSystem;
