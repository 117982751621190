import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowUp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const [showScroll, setShowScroll] = useState(false);
  const { t, i18n } = useTranslation()

  const date = new Date()

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className=" bg-black text-white py-12 relative">
      <div className="container mx-auto px-6 sm:px-8 lg:px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 md:gap-28">
        <div className="footer-info">
          <h4 className="text-white text-lg font-semibold mb-4" aria-label='tanıtım'>{t('footername')}</h4>
          <p className="mb-2"><strong>{t('istanbul_office')} </strong>Halim Ağa Sok. 5/3, Suadiye Kadıköy 34740 İstanbul</p>
          <p className="mb-2"><strong>{t('bodrum_office')} </strong> Nazmi Günel Sok. 14/3, Kumbahçe Bodrum 48400 Muğla</p>
        </div>

        {/* Quick Links */}
        <div className="footer-nav">
          <h4 className="text-white text-lg font-semibold  mb-4" aria-label='hızlı erişim'>{t('quicklink')} </h4>
          <div className="link-group flex flex-col gap-3">
            <Link to="/" onClick={handleLinkClick} className="text-white hover:underline ">{t('home')}</Link>

            <Link to="/ourservices" onClick={handleLinkClick} className="text-white hover:underline ">{t('services')}</Link>
            <Link to="/ourteam" onClick={handleLinkClick} className="text-white hover:underline">{t('about')}</Link>
          </div>
        </div>

        {/* Contact Information */}
        <div className="footer-info">
          <h4 className="text-white text-lg font-semibold mb-4"aria-label='pr'>{t('contact')} </h4>
          <div className="footer-contact">
            <p className="mb-2"><strong>Gsm: </strong> <a href="tel:+90532 256 9009" className="text-white hover:underline">+90 532 256 9009</a></p>
            <p className="mb-2"><strong>Email: </strong> <a href="mailto:drc@drciletisim.com" className="text-white hover:underline">drc@drciletisim.com</a></p>
          </div>
        </div>

        {/* Social Media */}
        <div className="footer-socials">
          <h4 className="text-white text-lg font-semibold mb-4"aria-label='sosyal medya'>{t('footer_follow')} </h4>
          <div className="flex gap-5 justify-start md:justify-start">
            <a 
              href="https://tr.linkedin.com/company/drc-strategic-marketing-communications" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-white hover:scale-125"
            >
              <FaLinkedin size={24} />
            </a>
            <a 
              href="https://www.instagram.com/drciletisim/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-white hover:scale-125"
            >
              <FaInstagram size={24} />
            </a>
            <a 
              href="https://www.facebook.com/people/Drc-Communication-Consultancy/100054628141265/" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="text-white hover:scale-125"
            >
              <FaFacebook size={24} />
            </a>
          </div>
        </div>
      </div>

     
      {showScroll && (
        <div 
          onClick={() => window.scrollTo(0, 0)} 
          className="scroll-to-top fixed bottom-4 right-4 bg-primary text-white p-4 rounded-full cursor-pointer hover:bg-white hover:text-black transition duration-200 ease-in-out"
        >
          <FaArrowUp size={20} />
        </div>
      )}

 
      <div className="footer-bottom text-center text-sm text-white mt-10 border-t-2 border-gray-100 pt-5">
        <p>&copy; {date.getFullYear()} {t('footername')}. <span className="block sm:inline"> {t('footer_text')}</span></p>
      </div>
    </footer>
  );
};
 
export default Footer;
