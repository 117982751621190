import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation, Trans } from 'react-i18next';
import duygu from '../assets/images/team/duygu.webp';
import beste from '../assets/images/team/beste.webp';
import nilufer from '../assets/images/team/nilufer.webp';
import sinem from '../assets/images/team/sinem.webp';
import yurttas from '../assets/images/team/yurttas.webp';
import eda from '../assets/images/team/edakarakisla.webp';

const teamMembers = [
  {
    name: 'duyguname, duyguname2',
    title: 'duygu_title',
    description: 'aboutduygu, aboutduygu1,aboutduygu2,aboutduygu3,aboutduygu4',
    photo: duygu,
    isMain: true,
  },
  {
    name: 'beste_name',
    title: 'beste_title',
    description: 'beste_description',
    photo: beste,
    isMain: false,
  },
  {
    name: 'nilufer_name',
    title: 'nilufer_title',
    description: 'nilufer_description',
    photo: nilufer,
    isMain: false,
  },
  {
    name: 'sinem_name',
    title: 'sinem_title',
    description: 'sinem_description',
    photo: sinem,
    isMain: false,
  },
  {
    name: 'yurttas_name',
    title: 'yurttas_title',
    description: 'yurttas_description',
    photo: yurttas,
    isMain: false,
  },
  {
    name: 'eda_name',
    title: 'eda_title',
    description: 'eda_description',
    photo: eda,
    isMain: false,
  },
];

const About = () => {
  const { t } = useTranslation();
  

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <>
      {/* Takım Üyeleri Başlık */}
      <div className=" p-6 text-center mb-14  "> 
      <div className="bg-primary p-4 sm:p-6    rounded-t-xl shadow-lg ">
    
   

        <h2 className="text-xl sm:text-2xl lg:text-2xl font-bold text-center tracking-wide text-white mb-16" aria-label="Agency">
          {t('team')}
        </h2>
      </div>
      </div>
      {/* Duygu'nun profili - Kırmızı alanda */}
      <div className=" p-6 text-center mb-14 rounded-lg -mt-14"data-aos="fade-up">
        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg -mt-24 ">
          <div className="flex justify-center mb-4">
            <img
              src={duygu}
              alt={t('team_members.duyguname')}
              className="w-72 h-[400px] object-cover shadow-lg"
              style={{ objectFit: 'cover' }}
            />
          </div>
          <h3 className="text-xl sm:text-2xl lg:text-2xl font-semibold text-center text-black mb-2" aria-label="marketing">
            {t('team_members.duyguname')}
          </h3>
          <h3 className="text-lg sm:text-lg lg:text-lg font-semibold text-center text-black mb-2" aria-label="organizasyon">
            {t('team_members.duyguname2')}
          </h3>
          <p className="text-lg sm:text-lg lg:text-lg font-bold text-center text-black mb-4">{t('team_members.duygu_title')}</p>

          {/* Duygu'nun Açıklamaları */}
          <div className="text-black text-start mb-4">
            <Trans i18nKey="team_members.aboutduygu" />
          </div>
          <div className="text-black text-start mb-4">
            <Trans i18nKey="team_members.aboutduygu1" />
          </div>
          <div className="text-black text-start mb-4">
            <Trans i18nKey="team_members.aboutduygu2" />
          </div>
          <div className="text-black text-start mb-4">
            <Trans i18nKey="team_members.aboutduygu3" />
          </div>
          <div className="text-black text-start mb-4">
            <Trans i18nKey="team_members.aboutduygu4" />
          </div>
        </div>
      </div>

      {/* Diğer Takım Üyeleri - 3 kişi ikinci satır */}
      <div className="max-w-screen-xl mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mb-14 mt-10">

     
        {teamMembers
          .filter((member) => !member.isMain)
          .slice(0, 3) 
          .map((member, index) => (
            <div
              key={index}
              className="bg-white p-4 sm:p-6 rounded-lg shadow-lg "
              data-aos="fade-up"
            >
              <div className="flex justify-center mb-4">
                <img
                  src={member.photo}
                  alt={t(`team_members.${member.name}`)}
                  className="w-72 h-[400px] object-cover shadow-lg"
                  style={{ objectFit: 'cover' }}
                />
              </div>
              <h3 className="text-xl sm:text-2xl lg:text-2xl font-semibold text-center text-black mb-2" aria-label="açılış">
                {t(`team_members.${member.name}`)}
              </h3>
              <p className="text-lg sm:text-lg lg:text-lg font-bold text-center text-black mb-4">{t(`team_members.${member.title}`)}</p>
              <p className="text-black">{t(`team_members.${member.description}`)}</p>
            </div>
          
          ))}
      </div>      

      {/* Diğer Takım Üyeleri - 2 kişi üçüncü satır */}
      <div className="max-w-screen-xl mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-8 justify-center mt-10 mb-14">

        {teamMembers
          .filter((member) => !member.isMain)
          .slice(3, 5)  
          .map((member, index) => (
            <div
              key={index}
              className="bg-white p-4 sm:p-6 rounded-lg shadow-lg"
              data-aos="fade-up"
            >
              <div className="flex justify-center mb-4">
                <img
                  src={member.photo}
                  alt={t(`team_members.${member.name}`)}
                  className="w-72 h-[400px] object-cover shadow-lg"
                  style={{ objectFit: 'cover' }}
                />
              </div>
              <h3 className="text-xl sm:text-2xl lg:text-2xl font-semibold text-center text-black mb-2" aria-label="strateji">
                {t(`team_members.${member.name}`)}
              </h3>
              <p className="text-lg sm:text-lg lg:text-lg font-bold text-center text-black mb-4">{t(`team_members.${member.title}`)}</p>
              <p className="text-black">{t(`team_members.${member.description}`)}</p>
            </div>
     
          ))}
      </div>
    </>
  );
};

export default About;
