import { useTranslation } from 'react-i18next';
import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const features = [
    {
        name: 'name',
        description: [
            'stratejik',
            'stratejik1',
            'stratejik2',
            'stratejik3',
            'stratejik4',
            'stratejik5',
            'stratejik6',
            'stratejik7',
        ],
    },
    {
        name: 'socialname',
        description: [
            'social',
            'social1',
            'social2',
            'social3',
            'social4',
            'social5',
        ],
    },
    {
        name: 'medianame',
        description: [
            'media',
            'media1',
            'media2',
            'media3',
            'media4',
        ],
    },
    {
        name: 'corporate_design_identity',
        description: [
            'corporate_design_identity1',
            'corporate_design_identity2',
            'corporate_design_identity3',
            'corporate_design_identity4',
            'corporate_design_identity5',
            'corporate_design_identity6',
        ],
    },
    {
        name: 'visualname',
        description: [
            'visual1',
            'visual2',
            'visual3',
            'visual4',
        ],
    },
    {
        name: 'sectoral_relations',
        description: [
            'sectoral_relations1',
            'sectoral_relations2',
            'sectoral_relations3',
            'sectoral_relations4',
        ],
    },
    {
        name: 'research_studies',
        description: [
            'research_studies1',
            'research_studies2',
            'research_studies3',
            'research_studies4',
        ],
    },
    {
        name: 'organization_management',
        description: [
            'organization_management1',
            'organization_management2',
            'organization_management3',
            'organization_management4',
        ],
    },
    {
        name: 'international_relations',
        description: [
            'international_relations1',
            'international_relations2',
            'international_relations3',
        ],
    },
    {
        name: 'web_application_management',
        description: [
            'web_application_management1',
            'web_application_management2',
            'web_application_management3',
        ],
    },
];

export default function Services() {
    const { t } = useTranslation();

    React.useEffect(() => {
        AOS.init({
            duration: 400,
            easing: 'ease-in-out',
            once: true,
        });
    }, []);

    return (
        <div className="bg-white">
            <div className="flex flex-col lg:flex-row">
                {/* Sidebar */}
                <div className="w-full lg:w-[250px] bg-black text-white p-6 flex items-start lg:flex-col lg:space-y-4 mt-10">
                    <h2 className="text-lg font-bold tracking-wide" aria-label="Sosyal Medya">
                        {t('services1')}
                    </h2>
                </div>

                {/* Main Content */}
                <div className="w-full lg:w-[calc(100%-250px)] bg-white text-black p-6 lg:p-10 border-4 border-solid border-primary border-l-0 lg:m-10 relative">
                    {/* Content Sections */}
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {features.map((feature, index) => (
                            <div
                                key={feature.name}
                                className="mb-6 mt-10 max-w-md pl-10"
                                aria-labelledby={`section-${feature.name}`}
                            >
                                <h3
                                    className="text-lg font-bold flex items-center space-x-3"
                                    id={`section-${feature.name}`}
                                    aria-label="web tasarım"
                                >
                                    <span>{t(feature.name)}</span>
                                </h3>
                                <ul
                                    className="mt-4 space-y-1"
                                    aria-labelledby={`desc-${feature.name}`}
                                >
                                    {feature.description.map((item, index) => (
                                        <li
                                            key={index}
                                            className="flex items-baseline space-x-3"
                                            aria-label="ajans"
                                        >
                                            <div
                                                className="w-10 min-w-10 h-2 bg-primary"
                                                data-aos="fade-right"
                                                data-aos-delay={`${index * 50}`}
                                                data-aos-duration="400"
                                            ></div>
                                            <span>{t(item)}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
