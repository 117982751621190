import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './styles.css';
import { useTranslation } from 'react-i18next';

// Resimlerinizi import edin (RODL, SW, AHK, ve ALPPLAS)
import rodl1 from '../assets/images/rodlimages/rodl1.webp';
import rodl2 from '../assets/images/rodlimages/rodl2.webp';
import rodl3 from '../assets/images/rodlimages/rodl3.png';
import rodl5 from '../assets/images/rodlimages/rodl5.webp';
import rodl7 from '../assets/images/rodlimages/rodl7.png';
import rodl8 from '../assets/images/rodlimages/rodl8.png';
import rodl9 from '../assets/images/rodlimages/rodl9.webp';
import rodl11 from '../assets/images/rodlimages/rodl11.png';
import capital from '../assets/images/rodlimages/capital.png';

import sw from '../assets/images/sw/sw.webp';
import sw10 from '../assets/images/sw/sww.png'
import sw3 from '../assets/images/sw/sw.png';
import sw4 from '../assets/images/sw/sw4.webp';
import sw5 from '../assets/images/sw/klass.png';
import sw6 from '../assets/images/sw/image003.png';
import sw7 from '../assets/images/sw/image004.png';
import sw8 from '../assets/images/sw/image005.png';
import sw9 from '../assets/images/sw/image006.png';
import sw11 from '../assets/images/sw/image009.png';

import ahk from '../assets/images/ahk/ahk.webp';
import ahk1 from '../assets/images/ahk/dunya.png';
import ahk2 from '../assets/images/ahk/hurriyet.png';
import ahk3 from '../assets/images/ahk/Milliyet.png';
import ahk4 from '../assets/images/ahk/ahk4.webp';
import ahk5 from '../assets/images/ahk/ahk5.webp';
import ahk6 from '../assets/images/ahk/ahk6.png';
import ahk7 from '../assets/images/ahk/ahk7.png';
import ahk8 from '../assets/images/ahk/samdan.webp';

import alpplas from '../assets/images/alpplas/alpplas.webp';
import alpplas1 from '../assets/images/alpplas/alpplas1.webp';
import alpplas2 from '../assets/images/alpplas/alpplas2.webp';
import alpplas3 from '../assets/images/alpplas/alpplas3.webp';
import alpplas4 from '../assets/images/alpplas/alpplass.png';
import alpplas5 from '../assets/images/alpplas/alpplas5.png';
import alpplas6 from '../assets/images/alpplas/alpplas6.png';
import alpplas7 from '../assets/images/alpplas/alpplas7.png';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const WorksExamples = () => {
  const { t } = useTranslation();
  const images = [rodl1, rodl2, rodl3, rodl5, rodl7, rodl8, rodl9, rodl11, capital];
  const imagessw = [sw5, sw4, sw3, sw, sw6, sw7, sw8, sw9, sw10, sw11];
  const imagesahk = [ahk, ahk1, ahk2, ahk3, ahk4, ahk5, ahk6, ahk7, ahk8];
  const imagesalpplas = [alpplas, alpplas1, alpplas2, alpplas3, alpplas4, alpplas5, alpplas6, alpplas7];

  return (
    <div className="p-8 lg:p-16 bg-primary">
      <h1 className="text-xl sm:text-2xl  font-semibold text-white text-center tracking-wide mb-12"aria-label='çalışmalarımızdan örnekler'>{t('exampleswork')}</h1>
      <div className="p-5">





        <div className="flex flex-col gap-12">
          {/* İsviçre Konsolosluğu Slider */}
          <div className="w-full mx-auto p-2 sm:p-1 bg-white lg:p-2 ">
            <h2 className="text-lg sm:text-xl font-bold text-black mb-4 text-start p-4"aria-label='pazarlama'>{t('swissConsulate')}</h2>
            <Carousel responsive={responsive} infinite={true} autoPlay={true} swipeable={true} draggable={true}>
              {imagessw.map((image, index) => (
                <div key={index} className="px-4 sm:px-6">
                  <img
                    src={image}
                    alt={`Swiss Consulate Image ${index}`}
                    className="w-full object-cover"
                    style={{ height: 'auto', maxHeight: '500px' }}
                  />
                </div>
              ))}
            </Carousel>
          </div>
          {/* Rödl & Partner Slider */}
          <div className="w-full mx-auto p-2 sm:p-1 bg-white lg:p-2 ">
            <h2 className="text-lg sm:text-xl font-bold text-black mb-4 text-start p-4"aria-label='rodl'>{t('rodl')}</h2>
            <Carousel responsive={responsive} infinite={true} autoPlay={true} swipeable={true} draggable={true}>
              {images.map((image, index) => (
                <div key={index} className="px-4 sm:px-6">
                  <img
                    src={image}
                    alt={`RODL Image ${index}`}
                    className="w-full object-cover"
                    style={{ height: 'auto', maxHeight: '500px' }}
                  />
                </div>
              ))}
            </Carousel>
          </div>



          {/* AHK Slider */}
          <div className="w-full mx-auto p-2 sm:p-1 bg-white lg:p-2 ">
            <h2 className="text-lg sm:text-xl font-bold text-black mb-4 text-start p-4" aria-label='alman ticaret odası'>{t('ahk')}</h2>
            <Carousel responsive={responsive} infinite={true} autoPlay={true} swipeable={true} draggable={true}>
              {imagesahk.map((image, index) => (
                <div key={index} className="px-4 sm:px-6">
                  <img
                    src={image}
                    alt={`AHK Image ${index}`}
                    className="w-full object-cover"
                    style={{ height: 'auto', maxHeight: '500px' }}
                  />
                </div>
              ))}
            </Carousel>
          </div>

          {/* Alpplas Slider */}
          <div className="w-full mx-auto p-2 sm:p-1 bg-white lg:p-2 ">
            <h2 className="text-lg sm:text-xl font-bold text-black mb-4 text-start p-4"aria-label='alpplas'>{t('alpplas')}</h2>
            <Carousel responsive={responsive} infinite={true} autoPlay={true} swipeable={true} draggable={true}>
              {imagesalpplas.map((image, index) => (
                <div key={index} className="px-4 sm:px-6">
                  <img
                    src={image}
                    alt={`Alpplas Image ${index}`}
                    className="w-full object-cover"
                    style={{ height: 'auto', maxHeight: '500px' }}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorksExamples;
