// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(initReactI18next)  
  .use(Backend)  
  .init({
    lng: 'tr',  // Varsayılan dil
    fallbackLng: 'tr', // Yedek dil
    interpolation: {
      escapeValue: false,  // React ile uyumlu
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',  // Dil dosyalarını bu yoldan yüklüyor
    },
  });

export default i18n;
