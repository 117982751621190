import React, { useEffect, useState } from 'react';
import { TypeAnimation } from 'react-type-animation';
import AOS from 'aos';
import 'aos/dist/aos.css';
import header from '../assets/images/hhhhh.webp';
import Services from './Services';
import Works from './Works';
import WorksExamples from './WorksExamples';
import { useTranslation } from 'react-i18next';
import DrcSystem from './DrcSystem';
import Brand from './Brand';


const Home = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 2000, once: true });
  }, []);


  useEffect(() => {

    setTypedTextKey(Date.now());
  }, [i18n.language]);

  const [typedTextKey, setTypedTextKey] = useState(Date.now());

  return (
    <>
      <div
        className="relative w-full"
        aria-label="DRC İletişim - Stratejik Pazarlama ve İletişim Danışmanlığı"
      >
        {/* Arka Plan Görseli */}
        <div className="relative w-full h-screen">
          <img
            src={header}
            alt="DRC İletişim Stratejik Pazarlama ve İletişim Danışmanlığı"
            className="w-full h-full object-cover"
            loading="eager"
            decoding="async"
            style={{ objectFit: 'cover', height: '100vh', width: '100%' }}
            srcSet={`${header}?cache=true`}
          />

          <div className="absolute inset-0 bg-white bg-opacity-10 z-10"></div>
        </div>

        {/* Daktilo Animasyonu */}
        <div className="absolute inset-0 flex items-center justify-center z-20 p-4 sm:p-8 md:p-12 lg:p-20">
          <div className="w-full max-w-5xl mx-auto text-center sm:text-left">
            <h1
              className="text-lg xs:text-xl sm:text-xl md:text-3xl font-semibold tracking-wide leading-relaxed text-center whitespace-pre-wrap text-white"
              aria-label="DRC İletişim Stratejik Pazarlama ve İletişim Danışmanlığı"
            >
              <TypeAnimation
                key={typedTextKey}
                sequence={[
                  t('header'),
                  2000,
                ]}
                speed={50}
                repeat={0}
                style={{ whiteSpace: 'pre-wrap' }}
              />
            </h1>

          </div>
        </div>
      </div>


      <DrcSystem />
      <Brand />
      <Services />
      <WorksExamples />
      <Works />
    </>
  );
};

export default Home;
