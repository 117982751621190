import './i18n';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Services from './components/Services';
import About from './components/About';


function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        {/* Dil parametresine göre yönlendirme */}
        <Route path="/" element={<Home />} />
        <Route path="/ourservices" element={<Services />} />
        <Route path="/ourteam" element={<About />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
