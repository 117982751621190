import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import drclogo from '../assets/images/drclogoo.png';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [currentLang, setCurrentLang] = useState(i18n.language || 'en');
  const [isOpen, setIsOpen] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);
  const closeMenu = () => setIsOpen(false);

  const handleXButtonClick = () => {
    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 300);
    toggleMenu();
  };

  useEffect(() => {
    AOS.init({ duration: 1000 });
    setCurrentLang(i18n.language || 'en');
  }, [i18n.language]);

  const handleLogoClick = () => {
    navigate('/'); 
    window.location.reload(); 
  };
  

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setCurrentLang(lang);
  };

  return (
    <div className="top-0 p-4 bg-white w-full z-50">
      <nav className="max-w-screen-xl mx-auto px-6 flex items-center justify-between h-20">
        <div className="container mx-auto flex items-center w-full">
          <div
            className="flex items-center cursor-pointer"
            onClick={handleLogoClick}
          >
            <img src={drclogo} alt="DRC Logo" className="h-10 md:h-12" />
          </div>
       
          <div className="flex-grow justify-center space-x-9 hidden md:flex">
            <Link to="/" className="block py-3 text-lg  md:text-xl relative group tracking-wide" aria-label="Anasayfa">
              {t('home')}
              <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-primary transition-all duration-300 group-hover:w-full"></span>
            </Link>
            <Link to="/ourservices" className="block py-3 text-lg md:text-xl relative group tracking-wide" aria-label="Services">
              {t('services')}
              <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-primary transition-all duration-300 group-hover:w-full"></span>
            </Link>
            <Link to="/ourteam" className="block py-3 text-lg md:text-xl relative group tracking-wide" aria-label="About">
              {t('about')}
              <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-primary transition-all duration-300 group-hover:w-full"></span>
            </Link>
          </div>

          <div className="ml-auto hidden md:block">
            <button
              onClick={() => handleLanguageChange(currentLang === 'en' ? 'tr' : 'en')}
              className="md:flex hidden items-center justify-center w-12 h-12 md:w-16 md:h-16 bg-primary text-white rounded-full hover:bg-black hover:text-white transition duration-300"
              aria-label="Change Language"
            >
              {currentLang === 'tr' ? 'EN' : 'TR'}
            </button>
          </div>

          <div className="flex items-center space-x-2 md:hidden ml-auto">
            <button
              onClick={() => handleLanguageChange(currentLang === 'en' ? 'tr' : 'en')}
              className="w-12 h-12 bg-primary text-white rounded-full hover:bg-black hover:text-white transition duration-300"
              aria-label="Change Language"
            >
              {currentLang === 'tr' ? 'EN' : 'TR'}
            </button>

            <button
              className={`flex items-center justify-center w-12 h-12 bg-primary text-white rounded-full hover:bg-black hover:text-white transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}
              onClick={toggleMenu}
              aria-label="Toggle Menu"
            >
              {!isOpen ? (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-5 w-5 md:h-6 md:w-6">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-5 w-5 md:h-6 md:w-6">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              )}
            </button>
          </div>
        </div>
      </nav>

      {isOpen && (
        <div className="fixed inset-0 bg-black opacity-50 z-40" onClick={toggleMenu}></div>
      )}


<div
  className={`fixed top-0 right-0 h-full bg-black text-white w-full overflow-auto transform transition-all duration-500 ease-in-out z-[999] ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
>

        <div className="flex justify-end p-4">
          <button
            onClick={handleXButtonClick}
            className={`text-white text-3xl transition-all duration-300 ${isClicked ? 'animate-spin text-primary shadow-xl shadow-primary' : ''}`}
          >
            &times;
          </button>
        </div>

        <div className="flex flex-col justify-center items-center text-center p-4 space-y-6 mt-48">
          <Link to="/" className="block py-3 text-xl md:text-3xl relative group tracking-wide" onClick={closeMenu}>
            {t('home')}
            <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-primary transition-all duration-300 group-hover:w-full"></span>
          </Link>
          <Link to="/ourservices" className="block py-3 text-xl md:text-3xl relative group tracking-wide" onClick={closeMenu}>
            {t('services')}
            <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-primary transition-all duration-300 group-hover:w-full"></span>
          </Link>
          <Link to="/ourteam" className="block py-3 text-xl md:text-3xl relative group tracking-wide" onClick={closeMenu}>
            {t('about')}
            <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-primary transition-all duration-300 group-hover:w-full"></span>
          </Link>
       
          <div className="flex space-x-6 mt-8">
            <a href="https://tr.linkedin.com/company/drc-strategic-marketing-communications" target="_blank" rel="noopener noreferrer" className="w-12 h-12 flex justify-center items-center bg-black text-white rounded-full hover:bg-white  hover:text-black transition duration-300">
              <FaLinkedin size={24} />
            </a>
            <a href="https://www.instagram.com/drciletisim/" target="_blank" rel="noopener noreferrer" className="w-12 h-12 flex justify-center items-center bg-black text-white rounded-full hover:bg-white hover:text-black transition duration-300">
              <FaInstagram size={24} />
            </a>
            <a href="https://www.facebook.com/people/Drc-Communication-Consultancy/100054628141265/" target="_blank" rel="noopener noreferrer" className="w-12 h-12 flex justify-center items-center bg-black text-white rounded-full hover:bg-white  hover:text-black transition duration-300">
              <FaFacebook size={24} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
