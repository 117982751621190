import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import header from '../assets/images/header2.webp';  
import AOS from 'aos'; 
import 'aos/dist/aos.css';  

const Brand = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,  
      easing: 'ease-in-out', 
      once: true,  
    });
  }, []);

  return (
    <div className="flex flex-col-reverse md:flex-row h-auto md:h-screen overflow-x-hidden">
      <div 
        className="w-full md:w-1/2 bg-white flex items-center justify-center relative p-6 md:p-10 bg-gradient-to-br from-primary via-white to-white"
      >
        <div className="py-12 sm:py-24">
          <div className="mx-auto max-w-7xl px-6 lg:px-8" data-aos="zoom-in">
            <div className="mx-auto max-w-2xl text-center lg:text-center">
              <h2 className="mt-2 text-3xl sm:text-4xl font-semibold tracking-tight text-black" aria-label='pazarlama'>
                {t('answer')}
              </h2>
              <p className="mt-2 text-3xl sm:text-4xl font-semibold tracking-tight text-black">
                {t('answerr')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div 
        className="w-full md:w-1/3 md:ml-20 md:mt-36"
    
      >
        <img
          src={header}
          alt="Brand Header"
          className="object-cover shadow-2xl w-full h-[300px] md:h-[450px] "
          loading="lazy"
          aria-label="pr"
        />
      </div>
    </div>
  );
};

export default Brand;
